import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AiOutlineMenuUnfold } from 'react-icons/ai'

import './NavbarStyle.css'

const Navbar = () => {
    const [hide, setHide] = useState(true)
    return (
        <header className="header">
            <nav className="navbar">
                <div className="logo">
                    <Link to="/" className="logo-brand">
                        EbCode
                    </Link>
                </div>
                <ul className={hide ? `nav-items` : `nav-items show`}>
                    <li className="nav-item" onClick={(e) => setHide(!hide)}>
                        <Link to="/" className="nav-link">
                            Home
                        </Link>
                    </li>
                    <li className="nav-item" onClick={(e) => setHide(!hide)}>
                        <Link to="/about" className="nav-link">
                            About
                        </Link>
                    </li>
                    <li className="nav-item" onClick={(e) => setHide(!hide)}>
                        <Link to="/projects" className="nav-link">
                            Projects
                        </Link>
                    </li>
                    <li className="nav-item" onClick={(e) => setHide(!hide)}>
                        <Link to="/contact" className="nav-link">
                            Contact
                        </Link>
                    </li>
                </ul>
                <button className="toggle-menu" onClick={(e) => setHide(!hide)}>
                    <AiOutlineMenuUnfold className="menu-icon" />
                </button>
            </nav>
        </header>
    )
}

export default Navbar
