import './ContactStyles.css'
const Contact = () => {
    return (
        <section className="contact-section">
            <div className="content">
                <h3 className="intro-title">Get In Touch</h3>
                <p>Thank you for checking out my portfolio.</p>
                <p>
                    If you have any questions or concerns, <br />
                    please do not hesitate to send me a message.
                </p>
            </div>
            <form name="contact" method="post" className="form">
                <input type="hidden" name="form-name" value="contact" />
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder="Name"
                        required
                    />
                </div>
                <div className="form-group">
                    <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        required
                    />
                </div>
                <div className="form-group">
                    <textarea
                        name="message"
                        placeholder="Message"
                        required
                    ></textarea>
                </div>
                <button type="submit" className="btn-contact">
                    Send Message
                </button>
            </form>
        </section>
    )
}

export default Contact
