import './ProjectCardStyle.css'
import { projects } from '../../data'

const ProjectCard = () => {
    return (
        <>
            {projects.map((proj, i) => (
                <div className="project-card" key={proj.title}>
                    <div className="card-top">
                        <img
                            src={
                                require(`../../images/projects/${i}.png`)
                                    .default
                            }
                            alt={proj.title}
                        />
                    </div>
                    <div className="card-bottom">
                        <h4>{proj.title}</h4>
                        <p>{proj.description}</p>
                    </div>
                    <div className="btn-box">
                        <button type="button" className="github">
                            <a
                                href={proj.sites[0].github}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Github
                            </a>
                        </button>
                        <button type="button" className="website">
                            <a
                                href={proj.sites[1].website}
                                target="_blank"
                                rel="noreferrer"
                            >
                                Website
                            </a>
                        </button>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ProjectCard
