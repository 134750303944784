import './FooterStyle.css'

const Footer = () => {
    return (
        <footer className="footer">
            <div className="logo">
                <h2 className="logo-brand">EbCode</h2>
            </div>
            <p className="copyright">
                &copy;{new Date().getFullYear().toString()} || All rights
                reserved
            </p>
        </footer>
    )
}

export default Footer
