import HomeNavigation from '../components/HomeNavigation/HomeNavigation'

const Home = () => {
    return (
        <section className="home">
            <HomeNavigation />
        </section>
    )
}

export default Home
