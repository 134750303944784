import './AboutStyle.css'
import htmlLogo from '../images/hmtl-logo.png'
import cssLogo from '../images/css-logo.png'
import jsLogo from '../images/js-logo.png'
import reactLogo from '../images/react-logo.png'
import sassLogo from '../images/sass-logo.png'
import apiLogo from '../images/api-logo.png'
import nodeLogo from '../images/node-logo.png'
import expressLogo from '../images/express-logo.png'
import mongoLogo from '../images/mongo-logo.png'
import profile from '../images/profile.jpg'
const About = () => {
    return (
        <section className="about-section">
            <h3 className="about-title">about me.</h3>
            <div className="about-me-box">
                <div className="about-me-text">
                    <p>
                        Hello there, My name is Ebenezer Kankam. I was born and
                        raised in Ghana which is in west Africa. My family and I
                        moved to the U.S. when I was 11yrs old. I spent most of
                        my childhood in Philadelphia and for the past five
                        years, Nashville Tennessee has become my new home.
                    </p>
                    <p>
                        I attended Middle Tennessee State University where I
                        graduated with a degree in Business Administration. I
                        majored in computer information systems at MTSU. After,
                        taking a few programming courses at MTSU, I quickly fall
                        in love with programming. However, I never knew what to
                        do with my newfound interest which now has turned into a
                        passion.
                    </p>
                    <p>
                        After graduation, I enrolled in Vanderbilt Coding
                        Bootcamp to expand my knowledge on web development while
                        also taking multiple courses on Udemy, LinkedIn
                        Learning, and CodeCademy. My thirst for learning new
                        technologies and improving my skills continues to grow
                        every day. And it has been amazing, fun, and exciting
                        learning about web development. I Love web development
                        because it allows me to work with different technologies
                        such as Python, JavaScript, C#, SQL, and more. I love
                        building web applications and learning about new
                        technologies.
                    </p>
                </div>
                <div className="image-box">
                    <img src={profile} alt="profile" />
                </div>
            </div>
            <h3 className="about-title">skills.</h3>
            <div className="frontend">
                <div className="left">
                    <h3>Frontend</h3>
                </div>
                <div className="right">
                    <li className="frontend-item">
                        <img src={htmlLogo} alt="logo" />
                    </li>
                    <li className="frontend-item">
                        <img src={cssLogo} alt="logo" />
                    </li>
                    <li className="frontend-item">
                        <img src={jsLogo} alt="logo" />
                    </li>
                    <li className="frontend-item">
                        <img src={reactLogo} alt="logo" />
                    </li>
                    <li className="frontend-item">
                        <img src={sassLogo} alt="logo" />
                    </li>
                    <li className="frontend-item">
                        <img src={apiLogo} alt="logo" />
                    </li>
                </div>
            </div>
            <div className="backend">
                <div className="left">
                    <li className="backend-item">
                        <img src={nodeLogo} alt="logo" />
                    </li>
                    <li className="backend-item">
                        <img src={expressLogo} alt="logo" />
                    </li>
                    <li className="backend-item">
                        <img src={mongoLogo} alt="logo" />
                    </li>
                </div>
                <div className="right">
                    <h3>Backend</h3>
                </div>
            </div>
        </section>
    )
}

export default About
