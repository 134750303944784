import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai'

// Files
import './HomeStyle.css'
import resume from '../../resume.docx'
const HomeNavigation = () => {
    return (
        <>
            <div className="navigation-card about">
                <div className="inner-content">
                    <h1>About</h1>
                    <Link to="/about" className="link">
                        <AiOutlineArrowRight className="link-icon" />
                    </Link>
                </div>
            </div>
            <div className="navigation-card project">
                <div className="inner-content">
                    <h1>Projects</h1>
                    <Link to="/projects" className="link">
                        <AiOutlineArrowRight className="link-icon" />
                    </Link>
                </div>
            </div>
            <div className="navigation-card contact">
                <div className="inner-content">
                    <h1>Contact</h1>
                    <Link to="/contact" className="link">
                        <AiOutlineArrowRight className="link-icon" />
                    </Link>
                </div>
            </div>
            <div className="navigation-card resume">
                <div className="inner-content">
                    <h1>Resume</h1>
                    <button className="resume-btn">
                        {' '}
                        <a href={resume} rel="noreferrer" download="resume">
                            download Resume
                        </a>
                    </button>
                </div>
            </div>
        </>
    )
}

export default HomeNavigation
