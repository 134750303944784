export const projects = [
    {
        title: 'Alien Generator',
        description:
            'This was a team project application. My task was to build the front-end with a fellow classmate. This app was created to help to inspire game masters, artists, and players in the endeavor of character creation and design. Each lifeform will be generated with a random backstory, physical description, and list of abilities!',
        sites: [
            {
                github: 'https://github.com/tpgent01/alien-generator',
            },
            {
                website: 'http://alien-generator.herokuapp.com/',
            },
        ],
    },
    {
        title: 'Box Office',
        description:
            'This app was created for movie and tv lovers. Users can search their favorite tv shows and actors and actress to view more infomation about them',
        sites: [
            {
                github: 'https://github.com/kwabena95/box-office',
            },
            {
                website: 'https://boxofficeinfo.netlify.app/#/',
            },
        ],
    },
    {
        title: 'Taroble Cards',
        description:
            'This was a team project application. My task was to build the front-end with a fellow classmate. This app was created to give a user a new take on a tarot card reading that is unlike any other. Working with a bootcamp all star team, we put together a slick and fun tarot card web application showing off our newly aquired skills for our final project.',
        sites: [
            {
                github: 'https://github.com/MeaghanCampbell/taroble-cards',
            },
            {
                website: 'https://taroble-cards.herokuapp.com',
            },
        ],
    },

    {
        title: 'Tea Spot',
        description:
            'Tea Spot is a multi-page website for tea lovers. The website allows Tea Spot to showcase their services and products. Customers can send Tea Spot message using the contact form.',
        sites: [
            {
                github: 'https://github.com/kwabena95/tea-spot',
            },
            {
                website: 'https://teaspot.netlify.app/',
            },
        ],
    },
    {
        title: 'Tic-tac-toe',
        description:
            'A simple react tic-tac-toe game with modern design. The app offers the user the ability to check their game history by clicking on game history moves, keep track of the number of moves they make. When game is over, user can restart the game. ',
        sites: [
            {
                github: 'https://github.com/kwabena95/tic-tac-toe',
            },
            {
                website: 'https://moderntictactoegame.surge.sh/',
            },
        ],
    },
]
