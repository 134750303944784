import { Switch, Route } from 'react-router-dom'

// Files
import Navbar from './components/Navbar/Navbar'
import Home from './pages/Home'
import About from './pages/About'
import Projects from './pages/Projects'
import Contact from './pages/Contact'
import Footer from './components/Footer/Footer'

const App = () => {
    return (
        <div className="container">
            <Navbar />
            <main>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/projects" component={Projects} />
                    <Route path="/contact" component={Contact} />
                </Switch>
            </main>
            <Footer />
        </div>
    )
}

export default App
