import ProjectCard from '../components/ProjectCard/ProjectCard'
import './ProjectsStyles.css'
const Projects = () => {
    return (
        <section className="project-section">
            <ProjectCard />
        </section>
    )
}

export default Projects
